<template>
  <router-view></router-view>
</template>

<script>
import MainPage from './MainPage.vue';

export default {
  components: {
    MainPage,
  },
};
</script>
